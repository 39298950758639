<template>
  <div>
    <Header />
    <main>
      <router-view />
    </main>
    <Footer />
    <Icons class="hide" />
    <Banner/>
  </div>
</template>

<script>
import Header from './Header.vue';
import Footer from './Footer.vue';
import Icons from '../Icon/Icons.vue';
import Banner from '../Cookie/Banner.vue';
export default {
  components: {
    Header,
    Footer,
    Icons,Banner
  },
};
</script>

<style lang="scss" scoped></style>
