<template>
  <div>
    <Intro />
    <Offers />
    <Portfolio /> 
    <StartProject />
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Intro from '@/components/Intro/Intro.vue';
import Portfolio from '@/components/Portfolio/Portfolio.vue';
import Offers from '@/components/Offers/Offers.vue';
import StartProject from '@/components/StartProject/StartProject.vue';

export default {
  name: 'Home',
  components: {
    Intro,
    Portfolio,Offers ,
    StartProject,
  },
};
</script>
