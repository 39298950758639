<template>
  <header class="site-header">
    <nav class="card site-nav">
      <ul class="lang-list">
        <li class="lang-list__item" @click="ChangeLang('de')">DE</li>
        <li class="lang-list__item" @click="ChangeLang('en')">EN</li>
      </ul>
      <div class="theme">
        <span v-if="theme == 'light'" @click="ChangeTheme('dark')" class="theme-btn">
          <span class="v-hidden">Dark</span>
          <svg class="icon">
            <use xlink:href="#darklight"></use>
          </svg>
        </span>

        <span v-else @click="ChangeTheme('light')" class="theme-btn"
          ><span class="v-hidden">light</span>
          <svg class="icon">
            <use xlink:href="#darklight"></use>
          </svg>
        </span>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      theme: 'light',
    };
  },
  computed: {
    LANG() {
      return this.$store.getters.getSelectedLang.toUpperCase();
    },
  },
  methods: {
    ChangeTheme(theme) {
      this.theme = theme;
      if (theme == 'light') {
        document.body.classList.add(theme);
        document.body.classList.remove('dark');
      } else {
        document.body.classList.add(theme);
        document.body.classList.remove('light');
      }
    },
    ChangeLang(lang) {
      this.$store.commit('changeLang', lang);
      document.documentElement.lang = lang;
    },
  },
};
</script>

<style lang="scss">
.site-header {
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 100;
  .card {
    padding: 0.5rem 1.2rem;
  }
  .site-nav {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    gap: 1rem;
  }
  .lang-list {
    list-style: none;
    display: grid;
    margin: 0;
    padding: 0;
    grid-template-columns: repeat(2, max-content);
    gap: 1rem;
    li {
      cursor: pointer;
      &:hover {
        font-weight: 500;
      }
    }
  }
  .theme-btn {
    height: 30px;
    display: block;
  }
}
</style>
