<template>
  <section class="portfolio" id="portfolio">
    <div class="container">
      <h2 data-aos="fade-up">Portfolio</h2>

      <div class="pf-box">
        <Work data-aos="fade-up" />
        <AudioSamples data-aos="fade-up" />
      </div>
      <p class="protfolio-text" data-aos="fade-up">
        {{ Text.portfolio_text }}
      </p>
      <div class="reviews">
        <div class="fiverr" data-aos="fade-up">
          <a href="https://www.fiverr.com/chriswoelf" rel="noopener noreferrer" target="_blank">
            <span class="v-hidden">fiverr</span>
            <svg class="icon">
              <use xlink:href="#fiverr"></use>
            </svg>
          </a>
          <p>{{ Text.project_reviews_fiverr }}</p>
          <div class="stars">
            <svg class="icon">
              <use xlink:href="#star"></use>
            </svg>
            <svg class="icon">
              <use xlink:href="#star"></use>
            </svg>
            <svg class="icon">
              <use xlink:href="#star"></use>
            </svg>
            <svg class="icon">
              <use xlink:href="#star"></use>
            </svg>
            <svg class="icon">
              <use xlink:href="#star"></use>
            </svg>
          </div>
        </div>
        <div class="fiverr" data-aos="fade-up">
          <a href="https://www.upwork.com/workwith/christianwoelfig" rel="noopener noreferrer" target="_blank">
            <span class="v-hidden">upwork</span>
            <svg class="icon">
              <use xlink:href="#upwork"></use>
            </svg>
          </a>
          <p>{{ Text.project_reviews_upwork }}</p>
          <div class="stars">
            <svg class="icon">
              <use xlink:href="#star"></use>
            </svg>
            <svg class="icon">
              <use xlink:href="#star"></use>
            </svg>
            <svg class="icon">
              <use xlink:href="#star"></use>
            </svg>
            <svg class="icon">
              <use xlink:href="#star"></use>
            </svg>
            <svg class="icon">
              <use xlink:href="#star"></use>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Work from './Work';
import AudioSamples from './AudioSamples.vue';
export default {
  components: {
    Work,
    AudioSamples,
  },
  computed: {
    Text() {
      return this.$store.getters.getText;
    },
  },
};
</script>

<style lang="scss">
@import '../../assets/scss/_breakpoints';
.portfolio {
  padding: 5rem 0;
}
.protfolio-text{
  text-align: center;
  margin: 2rem 0 0;
  font-weight: 500;
  font-size: 1.1rem;
}
.fiverr {
  margin: 3rem auto;
  width: 300px;
  text-align: center;
  font-weight: 500;
  font-weight: 500;
  .icon {
    height: 60px;
    width: 60px;
  }
  p {
    margin: 0;
  }
  .stars {
    .icon {
      fill: #ffc100;
      height: 25px;
      width: 25px;
    }
  }
}

@include tablet {
  .pf-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    .audio-samples {
      justify-self: right;
    }
  }
  .reviews{
    display: grid;
    grid-template-columns: 50% 50%;
  }
}
</style>
