<template>
  <section class="service">
    <div class="container">
      <div class="card" data-aos="fade-up">
        <h2>{{ Text.service_title }}</h2>
        <ul>
          <li v-for="(item, idx) in Text.service_list" :key="idx">{{ item }}</li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    Text() {
      return this.$store.getters.getText;
    },
  },
};
</script>

<style lang="scss">
@import '../../assets/scss/_var';
.service {
  // border-bottom: 1px solid #222;
  background-color: #677381 !important;
  .container {
    padding: 4rem 1rem;
  }
  .card {
    margin: 0 auto;
    max-width: 100%;
  }
  ul {
    li {
      font-weight: 500;
      margin-bottom: 1rem;
    }
  }
}
</style>
