<template>
  <section class="start-project">
    <div class="container" data-aos="fade-up">
      <div class="card">
        <h2>{{ Text.cta_title }}</h2>
        <p>{{ Text.cta_text }}</p>
        <svg class="icon">
          <use xlink:href="#handshake"></use>
        </svg>
        <a href="mailto:office@woelfig.com" class="cta-btn">{{ Text.cta_btn }}</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    Text() {
      return this.$store.getters.getText;
    },
  },
};
</script>

<style lang="scss">
@import '../../assets/scss/_breakpoints';
@import '../../assets/scss/_var';
.start-project {
  background-size: cover;
  background-attachment: fixed;
  background-image: url('/img/contact_m.jpg');
  background-position: center center;

  display: grid;
  align-items: center;
  padding: 5rem 0 7rem;
  .card {
    margin: 0 auto;
    padding: 2rem;
    max-width: 500px;
    text-align: center;
    .icon {
      width: 150px;
      height: 150px;
    }
  }
}
@include tablet {
  .start-project {
     background-image: url('/img/contact_t.jpg');
    .card {
      margin: 0 auto;
      max-width: 600px;
    }
  }
}

@include desktop{
    .start-project {
     background-image: url('/img/contact_d.jpg');
  
  }
}
</style>
