<template>
  <footer class="site-footer">
    <div class="container">
      <div class="contact">
        <p>
          Christian Mathias Wölfig<br />
          <a href="mailto: office@woelfig.com"> office@woelfig.com</a> <br />
          <a href="tel:+43660/5578989">+43 660/5578989</a>
          <br />
          A-2105 Unterrohrbach, Wiesener Straße 20 <br />
          Österreich
        </p>
      </div>
      <div class="links">
        <ul class="list">
          <li><router-link to="/impressum" >Impressum</router-link></li>
          <li><router-link to="/datenschutz">Datenschutz</router-link></li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import '../../assets/scss/_breakpoints';
.site-footer {
  padding: 2.5rem 1rem;
  text-align: center;
  .contact {
    a {
      text-decoration: underline;
    }
  }
  .list {
    padding: 0;
    margin: 0;
    list-style: none;
    font-weight: 500;
  }
}
@include tablet {
  .site-footer {
    text-align: left;
    .container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .links {
        justify-self: end;
        font-size: 1.1rem;
      }
    }
  }
}
</style>
