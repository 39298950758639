import de from './de/_de';
import en from './en/_en';


const lang = {
  state: () => ({
    selected: 'de',
    // content: { de,en },
    content:{de,en}
  }),

  mutations: {
   
    changeLang(state, value) {
      state.selected = value;
    },
  },

  getters: {
    getSelectedLang(state) {
      return state.selected;
    },
    getText: (state) =>{
      return state.content[state.selected].text;
    },

  },
};

export default lang;