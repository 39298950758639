<template>
  <Layout />
</template>

<script>
import Layout from '@/components/Layout/Layout.vue';
export default {
  components: {
    Layout,
  },
};
</script>

<style lang="scss">
@import './assets/scss/app';
</style>
