<template>
  <div>
    <div class="player">
      <button @click="PlayAudio" class="player-btn">
        <span class="v-hidden">
          Play
        </span>
        <svg v-if="!isPlaying" class="icon">
          <use xlink:href="#play"></use>
        </svg>
        <svg v-else class="icon">
          <use xlink:href="#pause"></use>
        </svg>
      </button>
      <div class="progress-container">
        <div class="time">
          <span>{{ convertTime(audioCurrentTime) }}</span> / 
          <span>{{ convertTime(audioDuration) }}</span>
        </div>
        <label :for="label">
        <input type="range" min="0" max="100" @change="handleRange()" @input="handleRange()" :id="label" v-model="progressPercent" />
        <span class="hide">{{label}}</span>
        </label>
      </div>
    </div>
    <div class="playlist"></div>

    <audio ref="audio" preload="metadata">
      <source :src="music" type="audio/mp3" />
    </audio>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    music: {
      type: String,
      default: '/audio/1.mp3',
    },
    label:{
      type: String,
      default: 'audio'
    }
  },
  data() {
    return {
      audioDuration: 0,
      audioCurrentTime: 0,
      isPlaying: false,
      progressPercent: '0',
    };
  },
  methods: {
    //Convert audio current time from seconds to min:sec display
    convertTime(seconds) {
      const format = (val) => `0${Math.floor(val)}`.slice(-2);
      //   var hours = seconds / 3600;
      var minutes = (seconds % 3600) / 60;
      return [minutes, seconds % 60].map(format).join(':');
    },
    //Show the total duration of audio file
    totalTime() {
      const Audio = this.$refs.audio;
      if (Audio) {
        let seconds = Audio.duration;
        return this.convertTime(seconds);
      } else {
        return '00:00';
      }
    },
    //Display the audio time elapsed so far
    elapsedTime() {
      var audio = this.$refs.audio;
      if (audio) {
        var seconds = audio.currentTime;
        return this.convertTime(seconds);
      } else {
        return '00:00';
      }
    },

    PlayAudio() {
      let Audio = this.$refs.audio;
      if (!this.isPlaying) {
        Audio.currentTime = this.audioCurrentTime;
        Audio.play();
        this.isPlaying = true;
      } else {
        Audio.pause();
        this.isPlaying = false;
      }
    },

    WatchAudio() {
      console.log('Watch');
      let Audio = this.$refs.audio;
      this.audioCurrentTime = this.$refs.audio.currentTime;
      this.audioDuration = this.$refs.audio.duration;

      if (Audio.ended) {
        this.progressPercent = 100;
        this.isPlaying = false;
        this.audioCurrentTime = 0;
      } else {
        this.CalculateProgress();
      }
    },
    Audio_Duration() {
      this.duration = this.$refs.audio.duration;
    },
    CalculateProgress() {
      this.progressPercent = Math.floor((this.audioCurrentTime / this.audioDuration) * 100).toString();
    },
    handleRange() {
      let Audio = this.$refs.audio;
      Audio.pause();
      this.isPlaying = false;
      let number1 = parseInt(this.progressPercent);
      let duration = this.audioDuration;
      let x = (duration / 100) * number1;
      this.audioCurrentTime = x;
    },
  },
  mounted() {
    this.$refs.audio.addEventListener('timeupdate', this.WatchAudio);
    const Audio = this.$refs.audio;
    Audio.onloadedmetadata = () => {
      let x = Audio.duration;
      this.audioDuration = x;
    };
    Audio.muted = false;
    Audio.volume = 0.5;
  },
};
</script>

<style lang="scss">
@import '../../assets/scss/_var';

.player {
  display: grid;
  grid-template-columns: 50px 150px;
  gap: 1rem;
  grid-template-rows: 1fr;
  grid-template-areas: 'play progress';
  width: 250px;
  height: 100%;
  background-color: #222;
  padding: 0.2rem;
  border-radius: 2rem;
  color: $white;
  overflow: hidden;
  input {
  display: block;
  width: 90%;
  height: 4px;
  margin: 0.5rem 1rem 0.5rem 0;
  justify-self: start;
}
}

.progress-container {
  grid-area: progress;
  span {
  }

}



.player-btn {
  grid-area: play;
  border: none;
  background-color: transparent;
  display: block;
  justify-self: end;
  cursor: pointer;
  .icon {
    fill: $white;
    width: 20px;
    height: 20px;
  }
}
</style>
