<template>
  <div class="audio-samples">
    <ul class="list-rs">
      <li>
        <h3>{{ Text.audio_sample }} 1</h3>
        <AudioPlayer music="/audio/1_Woelfig_Demo_Multi_Welcoming.mp3"  label="audio_sample_1"/>
      </li>
      <li>
        <h3>{{ Text.audio_sample }} 2</h3>
        <AudioPlayer music="/audio/2_Woelfig_Demo_GER_Calm.mp3" label="audio_sample_2"/>
      </li>
      <li>
        <h3>{{ Text.audio_sample }} 3</h3>
        <AudioPlayer music="/audio/3_Woelfig_Demo_IVR.mp3"  label="audio_sample_3"/>
      </li>  <li>
        <h3>{{ Text.audio_sample }} 4</h3>
        <AudioPlayer music="/audio/4_Woelfig_Demo_Lotfilm.mp3" label="audio_sample_4" />
      </li>
    </ul>
  </div>
</template>

<script>
import AudioPlayer from './AudioPlayer.vue';
export default {
  components: {
    AudioPlayer,
  },
  computed: {
    Text() {
      return this.$store.getters.getText;
    },
  },
};
</script>

<style lang="scss">
.list-rs {
  h3{
    margin: 0;
  }
  list-style: none;
  margin: 0; 
  padding: 0;
  li{
    margin-bottom: 1rem;
  }
}
</style>
