<template>
  <section class="intro" id="intro">
    <div class="container">
      <div class="top" data-aos="fade-up">
        <div class="card">
          <Logo />
        </div>
        <div>
          <span class="card"> {{ Text.sub_title }}</span>
        </div>
      </div>
      <div class="middle" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
        <div class="avatar-box">

          <picture>
            <source media="(min-width:900px)" srcset="/img/avatar_d.png" />
            <source media="(min-width:600px)" srcset="/img/avatar_t.png" />
            <img class="avatar" src="img/avatar_m.png" alt="Christan Avatar" width="300" height="300" />
          </picture>

          <a href="mailto:office@woelfig.com" class="card">
            <svg class="icon">
              <use xlink:href="#email"></use>
            </svg>
            office@woelfig.com</a
          >
        </div>
        <div class="text">
          <div class="card">
            <h2>
              {{ Text.welcome }}
            </h2>
            <p>
              {{ Text.intro }}
            </p>
            <ul class="link-list">
              <li>
                <a href="https://www.fiverr.com/chriswoelf" rel="noopener noreferrer" target="_blank"
                  ><span class="v-hidden">Fiverr</span>
                  <svg class="icon">
                    <use xlink:href="#fiverr"></use>
                  </svg>
                </a>
              </li>
              <li>
                <a href="https://www.upwork.com/workwith/christianwoelfig" rel="noopener noreferrer" target="_blank"
                  ><span class="v-hidden">Upwork</span>
                  <svg class="icon">
                    <use xlink:href="#upwork"></use>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="bottom" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1200">
        <a href="mailto:office@woelfig.com" class="cta-btn">{{ Text.cta_btn }}</a>

        <span class="scroll-down">
          <svg class="icon">
            <use xlink:href="#down"></use>
          </svg>
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import Logo from '../Layout/Logo';
export default {
  components: {
    Logo,
  },
  computed: {
    Text() {
      return this.$store.getters.getText;
    },
  },
};
</script>

<style lang="scss">
@import '../../assets/scss/_breakpoints';
.intro {
  min-height: 100vh;
  width: 100%;
  background-size: cover;
  background-attachment: fixed;
  background-image: url('/img/bg_m.jpg');
  background-position: center center;
  .top {
    padding-top: 5rem;
    .card {
      margin: 0 auto 1rem;
    }
  }
  .middle {
    margin: 1rem 0;
    .avatar-box {
      max-width: 300px;
      margin: 0 auto 1rem;
      .avatar {
        max-width: 300px;
        width: 100%;
        display: block;
      }
      .card {
        max-width: 100%;
        display: grid;
        grid-template-columns: repeat(2, max-content);
        gap: 1rem;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 500;
      }
    }
    .link-list {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(2, max-content);
      justify-content: center;
      gap: 1rem;
      .icon {
        height: 40px;
        width: 40px;
      }
    }
  }
  .bottom {
    padding: 3rem;
    .scroll-down {
      margin: 3rem auto;
      display: block;
      text-align: center;
      .icon {
        fill: #fff;
      }
    }
  }
}

@include tablet {
  .intro {
    background-image: url('/img/bg_t.jpg');

    .top {
      .card {
      }
    }
    .middle {
      display: grid;
      grid-template-columns: 60% 40%;
      grid-template-areas: 'text avatar';
      align-items: flex-end;
      .avatar-box {
        grid-area: avatar;
        margin: 0 auto;
        .avatar {
        }
        .card {
        }
      }
      .text {
        grid-area: text;
      }
    }
    .bottom {
    }
  }
}

@include desktop {
  .intro {
    background-image: url('/img/bg_d.jpg');
  }
}
</style>
