<template>
  <div class="cookie-banner" v-show="!isCookieAllowed">
    <div class="container">
      <span class="cookie-title">
        Diese Seite verwendet Cookies
      </span>
      <p class="cookie-text">
        Diese Internetseite verwendet Cookies und Google Analytics für die Analyse und Statistik. Wir nutzen Cookies zu unterschiedlichen
        Zwecken, unter anderem zur Analyse und für personalisierte Marketing-Mitteilungen. Durch die weitere Nutzung der Website stimmen Sie
        der Verwendung zu. <router-link to="/datenschutz">Datenschutz</router-link>
      </p>
      <div>
        <label for="necessary">
          <input type="checkbox" name="necessary" id="necessary" v-model="Necessary" checked disabled />
          Notwendige Cookies
        </label>
        <label for="preference">
          <input type="checkbox" name="preference" id="preference" v-model="Preference" />
          Präferenz-Cookies
        </label>
        <label for="statistics">
          <input type="checkbox" name="statistics" id="statistics" v-model="Statistics" />
          Statistik-Cookies
        </label>
        <label for="marketing">
          <input type="checkbox" name="marketing" id="marketing" v-model="Marketing" />
          Marketing-Cookies
        </label>
      </div>
      <button @click="setCookieSelection()">Speichern</button>
      <button class="set-all" @click="setCookieSelection('allCookies')">Alle akzeptieren</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCookieAllowed: false,
      Necessary: true,
      Preference: false,
      Statistics: false,
      Marketing: false,
    };
  },
  methods: {
    setCookieValue(name, value, expireDay) {
      let a = new Date();
      let calculateTime = expireDay * 24 * 60 * 60 * 1000;
      a = new Date(a.getTime() + calculateTime);

      document.cookie = name + '=' + value + '; expires=' + a.toUTCString() + ';';
    },
    getCookieValueByName(cookieName) {
      const b = document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)');
      return b ? b.pop() : '';
    },
    setCookieSelection(option) {
      if (option === 'allCookies') {
        this.Necessary = true;
        this.Preference = true;
        this.Statistics = true;
        this.Marketing = true;
      }

      let CookiesTrue = '';

      if (this.Necessary) {
        CookiesTrue += 'necessary';
      }
      if (this.Preferences) {
        CookiesTrue += ', preferences';
      }
      if (this.Statistics) {
        CookiesTrue += ', statistics';
      }
      if (this.Marketing) {
        CookiesTrue += ', marketing';
      }

      /*  console.log(CookiesTrue); */
      this.setCookieValue('cookies_allowed', true, 60);
      this.setCookieValue('cookies_mode', [CookiesTrue], 60);

      this.LOAD_JAVASCRIPT(this.Necessary, this.Preferences, this.Statistics, this.Marketing);
      this.isCookieAllowed = true;
    },
    LOAD_JAVASCRIPT(cookieNecessary, cookiePreferences, cookieStatistics, cookieMarketing) {
      //    Load Javascript Preferences Cookies is Set to Allowed
      if (cookieNecessary === true) {
        //    Put Javascript for Necessary Cookies Here
        // console.log('cookie Necessary is Allowed');
      }
      if (cookiePreferences === true) {
        //    Put Javascript for Preferences Cookies Here
        //console.log('cookie Preferences is Allowed');
      }
      if (cookieStatistics === true) {
        //    Put Javascript for Statistics Cookies Here
        // console.log('cookie Statistics is Allowed');
      }
      if (cookieMarketing === true) {
        //    Put Javascript for Marketing Cookies Here
        //console.log('cookie Marketing is Allowed');
      }
    },
  },
  beforeMount() {
      let cookiesAllowed = this.getCookieValueByName('cookies_allowed');
    let cookies_mode = this.getCookieValueByName('cookies_mode');
    let cookies = cookies_mode.split(', ');
    //console.log(cookies);

    if (cookiesAllowed === 'true') {
      this.isCookieAllowed = true;
    } else {
      this.isCookieAllowed = false;
    }
    cookies.forEach((element) => {
      //console.log(element);

      if (element.toString() === 'necessary') {
        this.Necessary = true;
      }
      if (element.toString() === 'preferences') {
        this.Preferences = true;
      }
      if (element.toString() === 'statistics') {
        this.Statistics = true;
      }
      if (element.toString() === 'marketing') {
        this.Marketing = true;
      }
    });
    this.LOAD_JAVASCRIPT(this.Necessary, this.Preferences, this.Statistics, this.Marketing);
  },
};
</script>

<style lang="scss">
.cookie-banner {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  background-color: rgba(32, 32, 32, 0.99);
  z-index: 1000;
  padding: 1rem;
  color: #fff;
  .cookie-title {
    font-weight: 500;
    font-size: 1.5rem;
  }
  p {
    padding: 0;
    margin: 0;
      color: #fff;
  }
  a {
    color: blue;
  }
  button {
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    border: none;
    margin: 1rem 1rem 0 0;
    font-weight: 700;
    cursor: pointer;
    background: #f2f2f2;
  }
  .set-all {
    background-color: chartreuse;
  }
  label {
    display: block;
  }
}
</style>
