<template>
  <div class="work">
    <h3>{{ Text.project_title_1 }}</h3>

    <ul class="pf-list">
      <li>
        <a href="https://www.youtube.com/channel/UC_9kURQwQwdLKRpj7vDEHUA/videos" rel="noopener noreferrer" target="_blank">
          <svg class="icon">
            <use xlink:href="#youtube"></use>
          </svg>
          Interessante Fakten
        </a>
      </li>
      <li>
        <a href="https://www.youtube.com/c/EeOneGuys" rel="noopener noreferrer" target="_blank">
          <svg class="icon">
            <use xlink:href="#youtube"></use>
          </svg>
          VERRÜCKTEN LAB
        </a>
      </li>
      <li>
        <a href="https://youtu.be/8efPeOGOFHs?list=LL&t=35" rel="noopener noreferrer" target="_blank">
          <svg class="icon">
            <use xlink:href="#youtube"></use>
          </svg>
          KidsAlarm
        </a>
      </li>
    </ul>
    <h3>{{ Text.project_title_2 }}</h3>
    <ul class="pf-list">
      <li>
        <a
          href="https://www.audible.de/pd/Ihre-erste-Stelle-oder-Praktikum-im-Finanzsektor-Hoerbuch/B098R859K5"
          rel="noopener noreferrer"
          target="_blank"
        >
          <svg class="icon">
            <use xlink:href="#audible"></use>
          </svg>
          <span class="text-warp"> Ihre erste Stelle oder Praktikum im Finanzsektor </span>
        </a>
      </li>
    </ul>
    <h3>{{ Text.project_title_3 }}</h3>
    <ul class="pf-list">
      <li>
        <a href="https://www.youtube.com/channel/UCkN-0CX5ZlkBkYmmrnhhB_w/videos" rel="noopener noreferrer" target="_blank">
          <svg class="icon">
            <use xlink:href="#youtube"></use>
          </svg>
          mathe4alle
        </a>
      </li>
      <li>
        <a
          href="https://open.spotify.com/show/7sThOpJLw0SBZcEOcxIc9a?si=hGRDZKxMTuypQC_ohks5jw&dl_branch=1"
          rel="noopener noreferrer"
          target="_blank"
        >
          <svg class="icon">
            <use xlink:href="#spotify"></use>
          </svg>
          Von Nichtig
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  computed: {
    Text() {
      return this.$store.getters.getText;
    },
  },
};
</script>

<style lang="scss">
.work{
  margin: 0 0 1.5rem 0;
  h3{
    margin: 0 0 .5rem;
  }
}
.pf-list {
  list-style: none;
  margin: 0 0 1.5rem 0;
  padding: 0;
  li a {
    display: grid;
    grid-template-columns: 30px calc(100% - 40px);
    align-items: center;
    gap: 0.5rem;
    margin: 0 0 .3rem 0;
  }
}
</style>
